import conf from "../conf";
import axios from "axios";

const cookie = JSON.parse(localStorage.getItem(conf.cookie_name));

export const fetchData = (method, path, data) => {
  return new Promise(async (resolve) => {
    let result = await axios({
      method,
      url: conf.endpoint.app + path,
      data,
      headers: { Authorization: cookie.access_token },
    });
    if (result.data.error) alert(result.data.error.message);
    else resolve(result.data);
  });
};

export const getProfile = () => {
  return new Promise(async (resolve) => {
    let currentPath = window.location.pathname;
    let cookie = localStorage.getItem(conf.cookie_name);
    cookie = JSON.parse(cookie);
    if (cookie && cookie.access_token) {
      let me = await axios.get(conf.endpoint.tonkla + "/parents/me", {
        headers: { Authorization: cookie.access_token },
      });
      if (me.data.error) {
        localStorage.removeItem(conf.cookie_name);
        alert(me.data.error.message);
        window.location = process.env.PUBLIC_URL + "/";
      } else resolve(me.data);
    } else if (currentPath !== process.env.PUBLIC_URL + "/") {
      window.location = process.env.PUBLIC_URL + "/";
    } else {
      resolve(null);
    }
  });
};

export const getStampTime = (dt) => {
  return new Promise((resolve) => {
    var d = new Date();
    if (dt) d = new Date(dt);
    var dd = String(d.getDate()).padStart(2, "0");
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();
    var isoDate = yyyy + "-" + mm + "-" + dd;

    var h = String(d.getHours()).padStart(2, "0");
    var m = String(d.getMinutes()).padStart(2, "0");
    var s = String(d.getSeconds()).padStart(2, "0");
    resolve({
      iso_date: isoDate,
      time: h + ":" + m + ":" + s,
      detail: {
        day: dd,
        month: mm,
        year: yyyy,
        hour: h,
        minute: m,
        second: s,
      },
      stampTime: d.getTime(),
    });
  });
};

export const sortJSON = (arr, field) => {
  return new Promise(async (resolve) => {
    arr = arr.sort((a, b) =>
      a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0
    );
    resolve(arr);
  });
};

export const groupBy = (array, key) => {
  return new Promise(async (resolve) => {
    let r = [];
    for (let arr of array) {
      let i = r.findIndex((j) => j[key] === arr[key]);
      if (i < 0) r.push(arr);
    }
    resolve(r);
  });
};

export const getOptions = async (type, language) => {
  return new Promise(async (resolve) => {
    let match = {};
    if (language && language === "en") {
      match = {
        $and: [
          { type },
          { label_alt: { $ne: "" } },
          { label_alt: { $exists: true } },
        ],
      };
    } else {
      match = {
        $and: [{ type }, { label: { $ne: "" } }, { label: { $exists: true } }],
      };
    }
    let send = {
      collection: "options",
      match: match,
      limit: 0,
      sort: "priority",
    };
    let options = await axios.post(
      conf.endpoint.tonkla + "/parents/query",
      send,
      {
        headers: {
          Authorization: cookie.access_token,
        },
      }
    );
    resolve(options.data.result);
  });
};
