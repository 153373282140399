import React, { useEffect } from "react";
import conf from "./conf";

export default function Signout() {
  useEffect(() => {
    localStorage.removeItem(conf.cookie_name);
    window.location = process.env.PUBLIC_URL + "/login";
  }, []);

  return <div></div>;
}
