import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DialogZip from "../components/DialogZip";
import { fetchData } from "../js/main";
import { programs } from "../const";
import conf from "../conf";

import { GridSelect, GridInput, GridDatePicker } from "./InputComponents";

const styles = {
  title: {
    fontWeight: 500,
    margin: "30px auto 0px",
  },
  sub_title: {
    fontSize: ".8rem",
    margin: "0px auto 30px",
    color: "#070",
  },
};
export default function FormStudent({ student, options }) {
  const [form, setForm] = useState();
  useEffect(() => {
    (async () => {})();
  }, []);
  useEffect(() => {
    if (student) setForm(student);
  }, [student]);

  useEffect(() => {
    (async () => {
      if (options) {
      }
    })();
  }, [options]);

  function changeField(field, val) {
    setForm({ ...form, [field]: val });
  }
  function changeFields(fields) {
    let Form = JSON.parse(JSON.stringify(form));
    for (let field of Object.keys(fields)) {
      Form[field] = fields[field];
    }
    setForm(Form);
  }
  function copyAddress() {
    let Form = JSON.parse(JSON.stringify(form));
    setForm({
      ...form,
      current_house_type: Form.house_type,
      current_house_no: Form.house_no,
      current_village: Form.village,
      current_moo: Form.moo,
      current_soi: Form.soi,
      current_street: Form.street,
      current_sub_district: Form.sub_district,
      current_district: Form.district,
      current_province: Form.province,
      current_zip_code: Form.zip_code,
    });
  }
  async function save() {
    let result = await fetchData("put", "/log/student", form);
    if (result) alert("Save completed");
    else alert("ERROR");
  }
  return (
    <div>
      {form && options && (
        <>
          <PersonalInfo
            form={form}
            options={options}
            onChange={(field, val) => changeField(field, val)}
          />
          <StudentInfo form={form} onChange={changeField} />
          <AddressInfo
            form={form}
            onChange={changeField}
            onChangeFields={changeFields}
            onCopyAddress={copyAddress}
          />
          <Button
            variant="contained"
            startIcon={<Icon>save</Icon>}
            style={{ marginTop: "20px", width: "150px" }}
            onClick={save}
            disabled={conf.disable_edit}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
}

function PersonalInfo({ form, onChange, options }) {
  return (
    <div>
      <h3 style={styles.title}>ข้อมูลส่วนตัวนักเรียน</h3>
      <div style={styles.sub_title}>Student Information</div>
      <Grid container spacing={2}>
        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Prefix"
          id="prefix"
          options={options.student_prefixs || []}
          onChange={(val) => onChange("prefix", val)}
          value={form.prefix || "-"}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="First name"
          placeholder="ชื่อ"
          id="first_name"
          onChange={(val) => onChange("first_name", val)}
          value={form.first_name}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Middle name"
          placeholder="ชื่อกลาง"
          onChange={(val) => onChange("middle_name", val)}
          value={form.middle_name}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Last name"
          placeholder="นามสกุล"
          id="last_name"
          onChange={(val) => onChange("last_name", val)}
          value={form.last_name}
          required
        />

        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Prefix (EN)"
          id="prefix_en"
          options={options.student_prefixs_en || []}
          onChange={(val) => onChange("prefix_en", val)}
          value={form.prefix_en || "-"}
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="First name (EN)"
          placeholder="ชื่อ (EN)"
          onChange={(val) => onChange("first_name_en", val)}
          value={form.first_name_en}
          id="first_name_en"
          required
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Middle name (EN)"
          placeholder="ชื่อกลาง (EN)"
          onChange={(val) => onChange("middle_name_en", val)}
          value={form.middle_name_en}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Last name (EN)"
          placeholder="นามสกุล (EN)"
          onChange={(val) => onChange("last_name_en", val)}
          value={form.last_name_en}
          id="last_name_en"
          required
        />

        <GridInput
          grid={[12, 12, 4, 4]}
          label="Nickname"
          placeholder="ชื่อเล่น"
          onChange={(val) => onChange("nickname", val)}
          value={form.nickname}
          id="nickname"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Nickname (EN)"
          placeholder="ชื่อเล่น (EN)"
          onChange={(val) => onChange("nickname_en", val)}
          value={form.nickname_en}
          id="nickname_en"
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Gender"
          // options={genders}
          options={options.gender || []}
          onChange={(val) => onChange("gender", val)}
          value={form.gender}
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="ID card number"
          placeholder="เลขบัตรประชาชน"
          onChange={(val) => onChange("idx", val)}
          value={form.idx}
          id="idx"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Passport number"
          placeholder="เลขหนังสือเดินทาง"
          onChange={(val) => onChange("passport_no", val)}
          value={form.passport_no}
        />
        <GridDatePicker
          grid={[12, 12, 4, 4]}
          label="Birth date"
          placeholder="วันเกิด"
          onChange={(val) => {
            onChange("birth_date", val);
          }}
          value={form.birth_date}
          id="birth_date"
          required
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Nationality"
          options={options.nationalities}
          onChange={(val) => onChange("nationality", val)}
          value={form.nationality}
          id="nationality"
          required
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Origin"
          options={options.origins || []}
          onChange={(val) => onChange("origin", val)}
          value={form.origin}
          id="origin"
          required
        />
        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Religion"
          options={options.religions || []}
          onChange={(val) => onChange("religion", val)}
          value={form.religion}
          required
          id="religion"
        />
      </Grid>
    </div>
  );
}

function StudentInfo({ form, onChange }) {
  return (
    <div>
      <h3 style={styles.title}>รายละเอียดนักเรียน</h3>
      <div style={styles.sub_title}>Study Information</div>
      <Grid container spacing={2}>
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Student ID"
          placeholder="เลขประจำตัวนักเรียน"
          value={form.student_code}
          disabled={true}
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="Group"
          placeholder="กลุ่มเรียน"
          value={form.current_group}
          disabled={true}
        />

        <GridSelect
          grid={[12, 12, 4, 4]}
          label="Blood"
          options={[
            { label: "A" },
            { label: "B" },
            { label: "AB" },
            { label: "O" },
          ]}
          onChange={(val) => onChange("blood_group", val)}
          value={form.blood_group}
          id="blood_group"
          required
        />
        <GridInput
          grid={[12, 12, 4, 4]}
          label="e-mail"
          placeholder="อีเมล"
          onChange={(val) => onChange("account", val)}
          value={form.account}
          disabled={true}
        />
        <GridSelect
          grid={[12, 12, 8, 8]}
          label="Program"
          options={programs}
          onChange={(val) => onChange("program", val)}
          value={form.program}
          disabled={true}
        />

        <GridInput
          grid={[12, 12, 12, 12]}
          label="Note"
          rows={3}
          placeholder="บันทึกอื่น ๆ"
          onChange={(val) => onChange("student_note", val)}
          value={form.student_note}
          disabled={true}
        />
      </Grid>
    </div>
  );
}

function AddressInfo({ form, onChange, onCopyAddress, onChangeFields }) {
  const [dialogState, setDialogState] = useState({ open: false });
  const house_type = [
    { label: "บ้านของตนเอง" },
    { label: "บ้านเช่า" },
    { label: "หอพัก" },
  ];
  function selectArea(area) {
    if (dialogState.type === "current") {
      onChangeFields({
        current_sub_district: area.area,
        current_district: area.district,
        current_province: area.province,
      });
    } else {
      onChangeFields({
        sub_district: area.area,
        district: area.district,
        province: area.province,
      });
    }
    setDialogState({ open: false });
  }
  return (
    <div>
      <DialogZip
        state={dialogState}
        onClose={() => setDialogState({ open: false })}
        onSelect={selectArea}
      />
      <h3 style={styles.title}>ข้อมูลที่อยู่ตามทะเบียนบ้าน</h3>
      <div style={styles.sub_title}>Registration Address</div>
      <Grid container spacing={2}>
        <GridInput
          grid={[12, 6, 4, 4]}
          label="House No"
          placeholder="บ้านเลขที่"
          required
          onChange={(val) => onChange("house_no", val)}
          value={form.house_no}
          id="house_no"
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Village"
          placeholder="หมู่บ้าน"
          onChange={(val) => onChange("village", val)}
          value={form.village}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Moo"
          placeholder="หมู่"
          onChange={(val) => onChange("moo", val)}
          value={form.moo}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="Soi"
          placeholder="ซอย"
          onChange={(val) => onChange("soi", val)}
          value={form.soi}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Street"
          placeholder="ถนน"
          onChange={(val) => onChange("street", val)}
          value={form.street}
        />

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label="Zip"
            InputLabelProps={{ shrink: true }}
            placeholder="รหัสไปรษณีย์"
            required
            id="zip_code"
            fullWidth
            size="small"
            value={form.zip_code || ""}
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() =>
                    setDialogState({
                      open: true,
                      type: "main",
                      code: form.zip_code,
                    })
                  }
                >
                  <Icon>search</Icon>
                </IconButton>
              ),
            }}
            onChange={(e) => onChange("zip_code", e.target.value)}
            onKeyUp={(e) =>
              e.key === "Enter" &&
              setDialogState({
                open: true,
                type: "main",
                code: form.zip_code,
              })
            }
          />
        </Grid>
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Sub-district"
          placeholder="ตำบล"
          required
          id="sub_district"
          onChange={(val) => onChange("sub_district", val)}
          value={form.sub_district}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="District"
          placeholder="อำเภอ"
          required
          id="district"
          onChange={(val) => onChange("district", val)}
          value={form.district}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Province"
          placeholder="จังหวัด"
          required
          id="province"
          onChange={(val) => onChange("province", val)}
          value={form.province}
        />
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <h3 style={styles.title}>ข้อมูลที่อยู่ปัจจุบัน</h3>
          <div style={styles.sub_title}>Current Address</div>
        </Grid>
        <Grid item xs={6} align="right" style={{ paddingTop: "30px" }}>
          <Button
            color="primary"
            component="span"
            onClick={onCopyAddress}
            startIcon={<Icon>file_copy</Icon>}
          >
            Same registration address
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <GridSelect
          grid={[12, 6, 3, 3]}
          label="House type"
          options={house_type}
          onChange={(val) => onChange("current_house_type", val)}
          value={form.current_house_type}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="House No"
          placeholder="บ้านเลขที่"
          required
          id="current_house_no"
          onChange={(val) => onChange("current_house_no", val)}
          value={form.current_house_no}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Village"
          placeholder="หมู่บ้าน"
          onChange={(val) => onChange("current_village", val)}
          value={form.current_village}
        />
        <GridInput
          grid={[12, 6, 2, 2]}
          label="Moo"
          placeholder="หมู่"
          onChange={(val) => onChange("current_moo", val)}
          value={form.current_moo}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="Soi"
          placeholder="ซอย"
          onChange={(val) => onChange("current_soi", val)}
          value={form.current_soi}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Street"
          placeholder="ถนน"
          onChange={(val) => onChange("current_street", val)}
          value={form.current_street}
        />

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label="Zip"
            InputLabelProps={{ shrink: true }}
            placeholder="รหัสไปรษณีย์"
            fullWidth
            id="current_zip_code"
            required
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton
                  edge="end"
                  onClick={() =>
                    setDialogState({
                      open: true,
                      type: "current",
                      code: form.current_zip_code,
                    })
                  }
                >
                  <Icon>search</Icon>
                </IconButton>
              ),
            }}
            value={form.current_zip_code || ""}
            onChange={(e) => onChange("current_zip_code", e.target.value)}
            onKeyUp={(e) =>
              e.key === "Enter" &&
              setDialogState({
                open: true,
                type: "main",
                code: form.current_zip_code,
              })
            }
          />
        </Grid>
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Sub-district"
          placeholder="ตำบล"
          required
          id="current_sub_district"
          onChange={(val) => onChange("current_sub_district", val)}
          value={form.current_sub_district}
        />

        <GridInput
          grid={[12, 6, 4, 4]}
          label="District"
          placeholder="อำเภอ"
          required
          id="current_district"
          onChange={(val) => onChange("current_district", val)}
          value={form.current_district}
        />
        <GridInput
          grid={[12, 6, 4, 4]}
          label="Province"
          required
          id="current_province"
          placeholder="จังหวัด"
          onChange={(val) => onChange("current_province", val)}
          value={form.current_province}
        />
      </Grid>
    </div>
  );
}
