const on_dev = false;
module.exports = {
  fbKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
  fbAuthDomain: "tonkla-a1378.firebaseapp.com",
  endpoint: {
    tonkla:
      on_dev === true
        ? "http://localhost:3001"
        : // "https://tonkla-backend.azurewebsites.net"
          "https://tonkla-backend.azurewebsites.net",
    app:
      on_dev === true
        ? "http://localhost:3001/parents"
        : // "https://tonkla-backend.azurewebsites.net"
          "https://tonkla-backend.azurewebsites.net/parents",
  },
  loginLanding: process.env.PUBLIC_URL + "/person",
  cookie_name: "tonkla-parent-cookie",
  disable_edit: true,
};
