import React, { Component } from "react";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import PageNameBox from "./components/PageNameBox";

// import Conf from "./Conf";
import { getProfile } from "./js/main";

export default class Main extends Component {
  is_setPage = false;
  state = {};
  async componentDidMount() {
    if (this.is_setPage === false) {
      this.is_setPage = true;
      //   this.props.setPageFormat(true, true);
      var profile = await getProfile();
      this.setState({ profile });
    }
  }
  render() {
    const { profile } = this.state;
    const forms = [
      {
        icon: "exit_to_app_icon",
        label_en: "General request",
        label_th: "คำร้องทั่วไป",
        url: "Reg-TK001.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Resignation request",
        label_th: "คำร้องขอลาออก",
        url: "Reg-TK002.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Student card request",
        label_th: "คำร้องขอทำบัตรนักเรียน",
        url: "Reg-TK003.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Suspension request",
        label_th: "คำร้องขอพักการเรียน",
        url: "Reg-TK004.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Certifying request",
        label_th: "คำร้องรับรองสภาพนักเรียน",
        url: "Reg-TK005.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Return to class request",
        label_th: "คำร้องกลับเข้าเรียน",
        url: "Reg-TK006.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Re-Grade request",
        label_th: "ใบคำร้องรีเกรด",
        url: "Reg-TK007.pdf",
      },
      {
        icon: "exit_to_app_icon",
        label_en: "Note of leave",
        label_th: "ใบลา",
        url: "Reg-TK008.pdf",
      },
    ];
    return (
      <div className="content">
        {profile && (
          <>
            <PageNameBox page={{ label: "Download", icon: "download" }} />
            <Grid container spacing={2}>
              {forms.map((form, key) => (
                <Grid item xs={6} md={4} lg={4} key={key}>
                  <Card>
                    <Button
                      fullWidth
                      href={`${process.env.PUBLIC_URL}/form/${form.url}`}
                      target="_blank"
                    >
                      <CardContent
                        style={{
                          paddingBottom: "15px",
                          color: "#333",
                          textAlign: "center",
                        }}
                      >
                        <div>
                          <Icon style={{ color: "#ccc", fontSize: "5rem" }}>
                            {form.icon}
                          </Icon>
                        </div>
                        <div style={{ fontSize: "1.1rem", fontWeight: 400 }}>
                          {form.label_en}
                        </div>
                        <div>{form.label_th}</div>
                      </CardContent>
                    </Button>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    );
  }
}
