import axios from "axios";
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import FormStudent from "./components/FormStudent";
import FormCurator from "./components/FormCurator";
import PageNameBox from "./components/PageNameBox";
import { getOptions } from "./js/main";
import conf from "./conf";

const cookie = JSON.parse(localStorage.getItem(conf.cookie_name));
export default function Person(props) {
  const [student, setStudent] = useState();
  const [options, setOptions] = useState();
  const [state, setState] = useState({ tabNo: 0 });
  useEffect(() => {
    async function fetchOptions() {
      let student_prefixs = await getOptions("student_prefix");
      let prefixs = await getOptions("prefix");
      let student_prefixs_en = await student_prefixs.map((j) => {
        return { label: j.label_alt };
      });
      let prefixs_en = await prefixs.map((j) => {
        return { label: j.label_alt };
      });
      let origins = await getOptions("origin");
      let jobs = await getOptions("job");
      let nationalities = await getOptions("nationality");
      let religions = await getOptions("religion");
      let family_statuses = await getOptions("family_status");
      let person_status = await getOptions("person_status");

      setOptions({
        jobs,
        person_status,
        student_prefixs,
        student_prefixs_en,
        prefixs,
        prefixs_en,
        origins,
        nationalities,
        family_statuses,
        gender: [
          { label: "ชาย (Male)", value: "ชาย" },
          { label: "หญิง (Female)", value: "หญิง" },
        ],
        religions,
      });
    }
    (async () => {
      fetchOptions();
      let result = await axios.get(
        conf.endpoint.tonkla + "/parents/log/student",
        { headers: { Authorization: cookie.access_token } }
      );
      setStudent(result.data);
    })();
  }, []);

  return (
    <div className="content">
      {student && options && (
        <>
          <PageNameBox
            page={{ label: "Personal Information", icon: "person" }}
          />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={state.tabNo}
              onChange={(e, value) => setState({ ...state, tabNo: value })}
            >
              <Tab label="Student" />
              <Tab label="Curators" />
              {/* <Tab label="Health" /> */}
            </Tabs>
          </Box>
          {
            {
              0: <FormStudent student={student} options={options} />,
              1: <FormCurator student={student} options={options} />,
            }[state.tabNo]
          }
        </>
      )}
    </div>
  );
}
