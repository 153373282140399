import React from "react";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

export default function PageNameBox(props) {
  const { page } = props;
  return (
    <div>
      <Grid container style={{ margin: "10px auto 10px" }}>
        <Grid item style={{ width: "45px", paddingTop: "8px", color: "#333" }}>
          <Icon style={{ fontSize: "2rem" }}>{page.icon}</Icon>
        </Grid>
        <Grid item>
          <h1
            style={{
              padding: "0px 0px",
              margin: "0px",
              color: "#333",
              fontSize: "1.8rem",
            }}
          >
            {page.label}
          </h1>
        </Grid>
      </Grid>
    </div>
  );
}
