import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getProfile } from "./js/main";
import Person from "./Person";
import Login from "./Login";
import Download from "./Download";
import Signout from "./Signout";
import Header from "./components/Header";

function App(props) {
  const [profile, setProfile] = useState();
  const [path, setPath] = useState();
  const menus = [
    {
      component: Login,
      path: "/",
    },
    {
      component: Login,
      path: "/login",
    },
    {
      label: "Main Manu",
      menuId: 1,
      priority: 1,
    },
    {
      label: "Personal Information",
      menuId: 11,
      priority: 1,
      subOf: 1,
      icon: "person",
      component: Person,
      path: "/person",
    },
    {
      label: "Download",
      menuId: 12,
      priority: 1,
      subOf: 1,
      icon: "download",
      component: Download,
      path: "/download",
    },
    {
      label: "Sign Out",
      menuId: 13,
      priority: 1,
      subOf: 1,
      icon: "lock_open",
      component: Signout,
      path: "/signout",
    },
  ];

  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      let path = window.location.pathname;
      console.log(path);
      setPath(path);
      setProfile(profile);
    })();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {path &&
          path !== process.env.PUBLIC_URL + "/" &&
          path !== process.env.PUBLIC_URL + "/login" && (
            <Header menus={menus} />
          )}
        <Routes>
          {menus
            .filter((j) => j.component)
            .map((page, key) => (
              <Route
                key={key}
                path={`${process.env.PUBLIC_URL}${page.path}`}
                element={<page.component page={page} profile={profile} />}
              />
            ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
