import React, { useEffect, useState } from "react";
import { GridSelect, GridInput } from "./InputComponents";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
// import Icon from "@mui/material/Icon";
// import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
// import axios from "axios";
import Conf from "../conf";
import { fetchData } from "../js/main";

// const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  title: {
    fontWeight: 500,
    margin: "30px auto 0px",
  },
  sub_title: {
    fontSize: ".8rem",
    margin: "0px auto 0px",
    color: "#070",
  },
};

export default function FormCurator({ student, options }) {
  const [form, setForm] = useState(student);
  const [curators, setCurators] = useState();

  useEffect(() => {
    (async () => {
      if (student) {
        let result = await fetchData("get", "/log/curator");
        if (result) {
          if (result.curators) setCurators(result.curators || []);
          else setCurators(result || []);
        }
      }
    })();
  }, [student]);

  function changeField(field, value, type) {
    let Curators = JSON.parse(JSON.stringify(curators));
    let i = Curators.findIndex((j) => j.curator_type === type);
    if (i < 0) {
      Curators.push({
        curator_type: type,
        [field]: value,
      });
    } else {
      Curators[i][field] = value;
    }
    setCurators(Curators);
  }

  function changeStudentField(field, value) {
    setForm({ ...form, [field]: value });
  }

  async function save() {
    let result = await fetchData("put", "/log/curator", curators);
    if (result) alert("Save completed");
    else alert("ERROR");
  }
  return (
    <div>
      {curators && options && (
        <>
          <CuratorInfo
            options={options}
            curator={
              curators.filter((j) => j.curator_type === "father")[0] || {}
            }
            onChange={(field, value) => changeField(field, value, "father")}
            title="ข้อมูลบิดา"
            sub_title="Father Information"
          />
          <CuratorInfo
            options={options}
            curator={
              curators.filter((j) => j.curator_type === "mother")[0] || {}
            }
            // curator={form.filter((j) => j.curator_type === "mother")[0]}
            onChange={(field, value) => changeField(field, value, "mother")}
            title="ข้อมูลมารดา"
            sub_title="Mother Information"
          />
          <CuratorInfo
            options={options}
            curator={
              curators.filter((j) => j.curator_type === "curator")[0] || {}
            }
            type="curator"
            // curator={form.filter((j) => j.curator_type === "curator")[0]}
            onChange={(field, value) => changeField(field, value, "curator")}
            title="ข้อมูลผู้ปกครอง"
            sub_title="Curator Information"
          />
          <CuratorEmergencyInfo
            options={options}
            curator={
              curators.filter((j) => j.curator_type === "emergency")[0] || {}
            }
            onChange={(field, value) => changeField(field, value, "emergency")}
            title="บุคคลที่ติดต่อฉุกเฉิน"
            sub_title="Emergency contact"
          />

          <FamilyInfo
            options={options}
            form={form}
            onChange={(field, value) => {
              console.log(field, value);
              changeStudentField(field, value);
            }}
            title="ข้อมูลครอบครัว"
            sub_title="Family Information"
          />
          {/* <div style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              style={{ marginTop: "20px", width: "150px" }}
              startIcon={<Icon>save</Icon>}
              onClick={save}
            >
              Save
            </Button>
          </div> */}
          <Button
            variant="contained"
            startIcon={<Icon>save</Icon>}
            style={{ marginTop: "20px", width: "150px" }}
            onClick={save}
            disabled={Conf.disable_edit}
          >
            Save
          </Button>
        </>
      )}
    </div>
  );
}

function CuratorInfo({ type, curator, title, sub_title, options, onChange }) {
  const [elHeader, setElHeader] = useState();
  const [loaded, setLoaded] = useState(false);
  const [required, setRequired] = useState(false);
  // const [form, setForm] = useState(curator || {});
  /*
  job: String,
  salary: Number,
  curator_type: String,
  student_id: {
  */
  useEffect(() => {
    if (loaded === false) {
      let header = curator.curator_type;
      if (header) {
        setElHeader(header + "_");
        setRequired(true);
      } else setElHeader(null);
      setLoaded(true);
    }
  }, [curator, loaded]);

  return (
    <div>
      {loaded === true && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h3 style={styles.title}>{title}</h3>
              <div style={styles.sub_title}>{sub_title}</div>
            </Grid>
            <Grid item xs={6} align="right">
              {type !== "curator" && (
                <Grid
                  container
                  style={{ maxWidth: "350px", paddingTop: "20px" }}
                >
                  <Grid item>
                    <FormControlLabel
                      onChange={(e) =>
                        onChange("not_civil_registration", e.target.checked)
                      }
                      checked={curator.not_civil_registration || false}
                      control={<Checkbox />}
                      label={
                        <div style={{ fontSize: ".9rem" }}>
                          ไม่มีข้อมูลในทะเบียนราษฎร์
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      onChange={(e) => onChange("is_curator", e.target.checked)}
                      checked={curator.is_curator || false}
                      control={<Checkbox />}
                      label={
                        <div style={{ fontSize: ".9rem" }}>เป็นผู้ปกครอง</div>
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Prefix"
              options={options.prefixs || []}
              onChange={(val) => onChange("prefix", val)}
              value={curator.prefix}
              required={required}
              id={elHeader && elHeader + "prefix"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="First name"
              placeholder="ชื่อ"
              onChange={(val) => onChange("first_name", val)}
              value={curator.first_name}
              required={required}
              id={elHeader && elHeader + "first_name"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Middle name"
              placeholder="ชื่อกลาง"
              onChange={(val) => onChange("middle_name", val)}
              value={curator.curator_type && curator.middle_name}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Last name"
              placeholder="นามสกุล"
              onChange={(val) => onChange("last_name", val)}
              value={curator.last_name}
              required={required}
              id={elHeader && elHeader + "last_name"}
              disabled={curator.not_civil_registration}
            />

            <GridSelect
              grid={[12, 6, 3, 3]}
              label="Prefix (EN)"
              options={options.prefixs_en || []}
              onChange={(val) => onChange("prefix_en", val)}
              value={curator.prefix_en}
              required={required}
              id={elHeader && elHeader + "prefix_en"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="First name (EN)"
              placeholder="ชื่อ (EN)"
              onChange={(val) => onChange("first_name_en", val)}
              value={curator.first_name_en}
              required={required}
              id={elHeader && elHeader + "first_name_en"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Middle name (EN)"
              placeholder="ชื่อกลาง (EN)"
              onChange={(val) => onChange("middle_name_en", val)}
              value={curator.middle_name_en}
              // required={required}
              // id={elHeader && elHeader + "middle_name_en"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 3, 3]}
              label="Last name (EN)"
              placeholder="นามสกุล (EN)"
              onChange={(val) => onChange("last_name_en", val)}
              value={curator.last_name_en}
              required={required}
              id={elHeader && elHeader + "last_name_en"}
              disabled={curator.not_civil_registration}
            />
            {/* <GridInput
          grid={[12, 6, 6, 3]}
          label="Nationality"
          placeholder="เชื้อชาติ"
          onChange={(val) => onChange("nationality", val)}
          value={curator.nationality}
        />
        <GridInput
          grid={[12, 6, 6, 3]}
          label="Origin"
          placeholder="สัญชาติ"
          onChange={(val) => onChange("origin", val)}
          value={curator.origin}
        /> */}

            <GridInput
              grid={[12, 12, 4, 4]}
              label="Phone Number"
              placeholder="เบอร์โทรติดต่อ"
              onChange={(val) => onChange("phone_number", val)}
              value={curator.phone_number}
              required={required}
              id={elHeader && elHeader + "phone_number"}
              disabled={curator.not_civil_registration}
            />
            <GridSelect
              grid={[12, 12, 4, 4]}
              label="Nationality"
              options={options.nationalities || []}
              onChange={(val) => onChange("nationality", val)}
              value={curator.nationality}
              required={required}
              id={elHeader && elHeader + "nationality"}
              disabled={curator.not_civil_registration}
            />
            <GridSelect
              grid={[12, 12, 4, 4]}
              label="Origin"
              options={options.origins || []}
              onChange={(val) => onChange("origin", val)}
              value={curator.origin}
              required={required}
              id={elHeader && elHeader + "origin"}
              disabled={curator.not_civil_registration}
            />

            {type !== "curator" ? (
              <GridSelect
                grid={[12, 6, 4, 4]}
                label="Status"
                options={options.person_status || []}
                onChange={(val) => onChange("status", val)}
                value={curator.status}
                required={required}
                id={elHeader && elHeader + "status"}
                disabled={curator.not_civil_registration}
              />
            ) : (
              <GridInput
                grid={[12, 6, 4, 4]}
                label="Relation"
                placeholder="ความสัมพันธ์"
                onChange={(val) => onChange("relation", val)}
                value={curator.relation}
                required={required}
                id={elHeader && elHeader + "relation"}
                disabled={curator.not_civil_registration}
              />
            )}
            <GridSelect
              grid={[12, 6, 4, 4]}
              label="Job"
              options={options.jobs || []}
              onChange={(val) => onChange("job", val)}
              value={curator.job}
              required={required}
              id={elHeader && elHeader + "job"}
              disabled={curator.not_civil_registration}
            />
            <GridInput
              grid={[12, 6, 4, 4]}
              type="number"
              label="Salary"
              placeholder="เงินเดือน"
              onChange={(val) => onChange("salary", val)}
              value={curator.salary}
              required={required}
              id={elHeader && elHeader + "salary"}
              disabled={curator.not_civil_registration}
            />
          </Grid>
        </>
      )}
    </div>
  );
}

function CuratorEmergencyInfo({
  curator,
  title,
  sub_title,
  options,
  onChange,
}) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 style={styles.title}>{title}</h3>
          <div style={styles.sub_title}>{sub_title}</div>
        </Grid>
        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Prefix"
          options={options.prefixs || []}
          onChange={(val) => onChange("prefix", val)}
          value={curator.prefix}
          required
          id="emergency_prefix"
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="First name"
          placeholder="ชื่อ"
          onChange={(val) => onChange("first_name", val)}
          value={curator.first_name}
          required
          id="emergency_first_name"
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Middle name"
          placeholder="ชื่อกลาง"
          onChange={(val) => onChange("middle_name", val)}
          value={curator.middle_name}
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Last name"
          placeholder="นามสกุล"
          onChange={(val) => onChange("last_name", val)}
          value={curator.last_name}
          required
          id="emergency_last_name"
        />
        <GridInput
          grid={[12, 6, 6, 6]}
          label="Relation"
          placeholder="ความสัมพันธ์"
          onChange={(val) => onChange("relation", val)}
          value={curator.relation}
          required
          id="emergency_relation"
        />
        <GridInput
          grid={[12, 6, 6, 6]}
          label="Phone Number"
          placeholder="เบอร์โทรติดต่อ"
          onChange={(val) => onChange("phone_number", val)}
          value={curator.phone_number}
          required
          id="emergency_phone_number"
        />
      </Grid>
    </div>
  );
}

function FamilyInfo({ form, title, sub_title, options, onChange }) {
  const stay_with = [
    { label: "อยู่ด้วยกันกับบิดาและมารดา" },
    { label: "บิดา" },
    { label: "มารดา" },
    { label: "ญาติ" },
  ];
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 style={styles.title}>{title}</h3>
          <div style={styles.sub_title}>{sub_title}</div>
        </Grid>

        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Family status"
          options={options.family_statuses || []}
          onChange={(val) => onChange("family_status", val)}
          value={form.family_status}
          required
          id="family_status"
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          type="number"
          label="Brother number"
          placeholder="จำนวนพี่น้อง"
          onChange={(val) => onChange("brother_number", val)}
          value={form.brother_number}
          required
          id="brother_number"
        />
        <GridInput
          grid={[12, 6, 3, 3]}
          label="Study brother number"
          type="number"
          placeholder="จำนวนพี่น้องที่ศึกษาอยู่"
          onChange={(val) => onChange("study_brother_number", val)}
          value={form.study_brother_number}
        />

        <GridSelect
          grid={[12, 6, 3, 3]}
          label="Stay with"
          options={stay_with}
          onChange={(val) => onChange("stay_with", val)}
          value={form.stay_with}
          required
          id="stay_with"
        />
      </Grid>
    </div>
  );
}
