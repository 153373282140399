import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DatePicker from "@mui/lab/DatePicker";

import { getStampTime } from "../js/main";

export function GridSelect(props) {
  const {
    grid,
    options,
    label,
    size,
    onChange,
    value,
    placeholder,
    disabled_empty,
    required,
    id,
    disabled,
  } = props;
  return (
    <Grid item xs={grid[0]} sm={grid[1]} md={grid[2]} lg={grid[3]}>
      <FormControl
        fullWidth
        size={size || "small"}
        required={required}
        disabled={disabled || false}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          native
          id={id}
          label={label}
          value={value || "-"}
          onChange={(e) => onChange(e.target.value)}
        >
          {disabled_empty !== true && <option value="-">{placeholder}</option>}
          {options &&
            options.map((option, key) => (
              <option key={key} value={option.value || option.label}>
                {option.label}
              </option>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

export function GridInput(props) {
  const {
    grid,
    label,
    size,
    onChange,
    value,
    placeholder,
    rows,
    type,
    required,
    id,
    disabled,
  } = props;
  return (
    <Grid item xs={grid[0]} sm={grid[1]} md={grid[2]} lg={grid[3]}>
      <TextField
        id={id}
        value={value || ""}
        size={size || "small"}
        multiline={rows ? true : false}
        type={type || "text"}
        rows={rows || 1}
        label={label}
        placeholder={placeholder || ""}
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        InputLabelProps={{ shrink: true }}
        fullWidth
        required={required}
        disabled={disabled || false}
      />
    </Grid>
  );
}

export function GridDatePicker(props) {
  const { grid, label, size, onChange, value, placeholder, required, id } =
    props;
  return (
    <Grid item xs={grid[0]} sm={grid[1]} md={grid[2]} lg={grid[3]}>
      <InputDate
        label={label}
        size={size}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        required={required}
      />
    </Grid>
  );
}

export function InputDate(props) {
  const { label, size, onChange, value, placeholder, required, id } = props;
  async function onSelectDate(val) {
    let dt = await getStampTime(val);
    onChange(dt.iso_date);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={onSelectDate}
        placeholder={placeholder || ""}
        renderInput={(params) => (
          <TextField
            id={id}
            {...params}
            required={required || false}
            fullWidth
            size={size || "small"}
            style={{ background: "#fff" }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
