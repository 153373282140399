import React, { useEffect, useState } from "react";
import axios from "axios";

import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import conf from "./conf";
import { getProfile } from "./js/main";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const theme = createTheme();

export default function SignInSide() {
  const [loaded, setLoaded] = useState(false);
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: conf.fbKey,
      authDomain: conf.fbAuthDomain,
    });
  }
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: (e) => console.log(e),
    },
  };
  useEffect(() => {
    const renderPage = async () => {
      // localStorage.removeItem(conf.cookie_name);
      let profile = await getProfile();
      if (profile && profile.usn) {
        window.location = process.env.PUBLIC_URL + "/person";
      } else {
        await firebase.auth().signOut();
        setLoaded(true);
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            firebase
              .auth()
              .currentUser.getIdToken(true)
              .then(async function (idToken) {
                let access_token = await axios.get(
                  conf.endpoint.tonkla + "/parents/auth",
                  {
                    headers: { Authorization: idToken },
                  }
                );
                if (access_token.data.error) {
                  alert(access_token.data.error.message);
                  window.location = process.env.PUBLIC_URL + "/login";
                } else {
                  access_token = access_token.data.access_token;
                  localStorage.setItem(
                    conf.cookie_name,
                    JSON.stringify({ access_token: access_token })
                  );
                  window.location = process.env.PUBLIC_URL + "/person";
                }
              });
          }
        });
      }
    };
    renderPage();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      {loaded === true && (
        <Grid container component="main" sx={{ height: "100vh" }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/images/cover.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>

              <div className="container authContainer">
                <div className="row">
                  <div className="col s12" style={{ textAlign: "center" }}>
                    <h2
                      style={{
                        color: "#555",
                        fontSize: "2.8rem",
                        marginBottom: "13px",
                      }}
                    >
                      Authentication
                    </h2>
                    <hr
                      style={{
                        width: "70px",
                        background: "#4db6ac",
                        border: "1.2px solid #4db6ac",
                        margin: "auto",
                      }}
                    />
                  </div>
                  <div
                    className="col s12"
                    style={{ paddingTop: "30px", textAlign: "center" }}
                  >
                    <div>
                      <StyledFirebaseAuth
                        uiConfig={uiConfig}
                        firebaseAuth={firebase.auth()}
                      />
                      {/* <Button
                        onClick={() =>
                          localStorage.removeItem(conf.cookie_name)
                        }
                      >
                        Sign Out
                      </Button> */}
                    </div>
                  </div>
                  <div
                    className="col s12"
                    style={{
                      fontSize: ".8em",
                      fontWeight: "200",
                      color: "#bbb",
                      textAlign: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <i className="far fa-copyright"></i> All rights reserved
                    Tonkla school
                  </div>
                </div>
              </div>
              {/* <br />
            <br />
            <div>
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </div> */}
            </Box>
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  );
}
